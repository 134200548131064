import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { env } from "../../../env";
const baseUrl = env.REACT_APP_BASE_URL;

export const internalSingleApproval = createAsyncThunk(
  "internalSingleApproval",
  async (values) => {
    const { page_no, page_size, category, entityId } = values;
    try {
      const response = await axios.get(
        `${baseUrl}/transactions/internal/actionable`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
          params: {
            page_no: page_no ? page_no : undefined,
            page_size: page_size ? page_size : undefined,
            category: category !== undefined ? category : undefined,
            entity_id: entityId ? entityId : undefined,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const internalTxnApproval = createAsyncThunk(
  "internalTxnApproval",
  async (id) => {
    const data = {
      txn_id: [id],
    };
    const requestOptions = {
      method: "PUT",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify(data),
    };

    return await fetch(
      `${baseUrl}/transactions/internal/approve?`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return console.log("error", error);
      });
  }
);

export const internalTxnReject = createAsyncThunk(
  "internalTxnReject",
  async ({ id, AccountIdData }) => {
    const data = {
      txn_id: [id],
      reason: AccountIdData,
    };
    const requestOptions = {
      method: "PUT",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },

      body: JSON.stringify(data),
    };

    return await fetch(
      `${baseUrl}/transactions/internal/reject`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return console.log("error", error);
      });
  }
);

export const internalTxnMultiApproval = createAsyncThunk(
  "internalTxnMultiApproval",
  async (id, { rejectWithValue }) => {
    const data = {
      txn_id: id,
    };

    try {
      const response = await axios.put(
        `${baseUrl}/transactions/internal/approve`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const internalTxnMultiRejectApproval = createAsyncThunk(
  "internalTxnMultiRejectApproval",
  async ({ id, AccountIdData }, { rejectWithValue }) => {
    const data = {
      txn_id: id,
      reason: AccountIdData,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
    };

    try {
      const response = await axios.put(
        `${baseUrl}/transactions/internal/reject`,
        data,
        requestOptions
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);

const InternalTxnSingleApprovalSlice = createSlice({
  name: "InternalTxnSingleApproval",
  initialState: {
    isApprovalStatusSelected: false,
    storedInternalDataPage: 1,
    itxnToggle: false,

    internalTxnSingleApprovalData: [],
    internalTxnSingleApprovalLoading: false,
    internalTxnSingleApprovalError: false,

    internalTxnApprovalData: [],
    internalTxnApprovalLoading: false,
    internalTxnApprovalError: false,

    internalTxnRejectData: [],
    internalTxnRejectLoading: false,
    internalTxnRejectError: null,

    internalTxnMultiRejectApprovalData: [],
    internalTxnMultiRejectApprovalLoading: null,
    internalTxnMultiRejectApprovalError: null,

    internalTxnMultiApprovalData: [],
    internalTxnMultiApprovalLoading: null,
    internalTxnMultiApprovalError: null,
  },
  reducers: {
    setITxnToggle: (state, action) => {
      state.itxnToggle = action.payload;
    },
    resetToggle: (state) => {
      state.itxnToggle = false;
    },
    resetITMultiApprovalError: (state) => {
      state.internalTxnMultiApprovalError = false;
    },
    resetITMultiRejectApprovalLoading: (state) => {
      state.internalTxnMultiRejectApprovalLoading = null;
    },
    resetITMultiRejectApprovalData: (state) => {
      state.internalTxnMultiRejectApprovalError = false;
    },
    setITApprovalStatusSelected: (state, action) => {
      state.isApprovalStatusSelected = action.payload;
    },

    resetInternalPage: (state) => {
      state.storedInternalDataPage = 1;
    },
    setStoredInternalDataPage: (state, action) => {
      state.storedInternalDataPage = action.payload;
    },
    selectedApprovalFire(state, action) {
      state.selectedApproval = action.payload?.selectedArray;
      state.selectedApprovalId = action.payload?.selectedId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(internalSingleApproval.pending, (state, action) => {
      state.internalTxnSingleApprovalData = true;
    });
    builder.addCase(internalSingleApproval.fulfilled, (state, action) => {
      state.internalTxnSingleApprovalLoading = false;
      state.internalTxnSingleApprovalData = action.payload;
    });
    builder.addCase(internalSingleApproval.rejected, (state, action) => {
      state.internalTxnSingleApprovalError = true;
      state.internalTxnSingleApprovalData = null;
    });

    builder.addCase(internalTxnApproval.pending, (state, action) => {
      state.internalTxnApprovalLoading = true;
    });
    builder.addCase(internalTxnApproval.fulfilled, (state, action) => {
      state.BeneTxnDashBoardLoading = false;
      state.internalTxnApprovalData = action.payload;
    });
    builder.addCase(internalTxnApproval.rejected, (state, action) => {
      state.internalTxnApprovalError = true;
      state.internalTxnApprovalData = null;
    });
    builder.addCase(internalTxnReject.pending, (state, action) => {
      state.internalTxnRejectLoading = true;
    });
    builder.addCase(internalTxnReject.fulfilled, (state, action) => {
      state.internalTxnRejectLoading = false;
      state.internalTxnRejectData = action.payload;
    });
    builder.addCase(internalTxnReject.rejected, (state, action) => {
      state.internalTxnRejectError = true;
      state.internalTxnRejectData = null;
    });

    builder.addCase(internalTxnMultiApproval.pending, (state, action) => {
      state.internalTxnMultiApprovalLoading = true;
    });
    builder.addCase(internalTxnMultiApproval.fulfilled, (state, action) => {
      state.internalTxnMultiApprovalLoading = false;
      state.internalTxnMultiApprovalData = action.payload;
    });
    builder.addCase(internalTxnMultiApproval.rejected, (state, action) => {
      sessionStorage.setItem(
        "selectedDataError",
        JSON.stringify(action.payload?.errors?.base)
      );
      state.internalTxnMultiApprovalError = true;
      state.internalTxnMultiApprovalData = null;
    });

    builder.addCase(internalTxnMultiRejectApproval.pending, (state, action) => {
      state.internalTxnMultiRejectApprovalLoading = true;
    });
    builder.addCase(
      internalTxnMultiRejectApproval.fulfilled,
      (state, action) => {
        state.internalTxnMultiRejectApprovalLoading = false;
        state.internalTxnMultiRejectApprovalData = action.payload;
      }
    );
    builder.addCase(
      internalTxnMultiRejectApproval.rejected,
      (state, action) => {
        sessionStorage.setItem(
          "selectedDataError",
          JSON.stringify(action.payload?.errors?.base)
        );
        state.internalTxnMultiRejectApprovalLoading = null;
        state.internalTxnMultiRejectApprovalError = true;
        state.internalTxnMultiRejectApprovalData = null;
      }
    );
  },
});

export const {
  selectedApprovalFire,
  setITxnToggle,
  resetInternalPage,
  resetITMultiApprovalError,
  resetITMultiRejectApprovalLoading,
  resetITMultiRejectApprovalData,
  setStoredInternalDataPage,
  setIsApprovalStatusSelected,
  resetToggle,
} = InternalTxnSingleApprovalSlice.actions;
export default InternalTxnSingleApprovalSlice.reducer;
