import React, { useState, useEffect } from "react";
import "./index.css";
import xpentraloader from "../../../assets/Beneficiary Icons/lodemore.gif";
import info from "../../../assets/InfoIcons/Info.svg";
import transactionHistory from "../../../assets/GeneralIcons/transaction_history.svg";
import arrowRight from "../../../assets/ArrowsIcons/ChevronRightWhite.svg";
import arrow_right_black from "../../../assets/ArrowsIcons/arrow_right_white – 2.svg";
import arrow_down from "../../../assets/ArrowsIcons/arrow_down_white.svg";
import arrow_up from "../../../assets/ArrowsIcons/arrow_up_white.svg";
import arrow_right_white_filled from "../../../assets/ArrowsIcons/arrow_right_white_filled.svg";
import timeline_dot from "../../../assets/RadioIcons/timeline_dot.svg";
import declineImage from "../../../assets/RejectIcons/reject_icon_red_with_white_circle.svg";
import approveImage from "../../../assets/CheckmarksIcons/approve_circle_green_checkmark.svg";
import skipping_verification from "../../../assets/ErrorIcons/error_with_white_outlined_circle.svg";
import whiteLoader from "../../../assets/LoaderIcons/xpentra lodemore 1.svg";
import default_file from "../../../assets/FileFormatIcons/DefaultImage.svg";
import closeIcon from "../../../assets/General Icons/close.svg";
import error_with_white_outlined_circle from "../../../assets/ErrorIcons/error_with_white_outlined_circle.svg";
import { Tooltip, tooltipClasses } from "@mui/material";
import { toast, Bounce } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  internalTxnAccountDetail,
  internalTxnRequireApprovalPage,
  internalTxnApproval,
} from "../../../redux/features/internalTransaction/internalTransactionDetailPage";
import formatDateViewAll from "../../../utils/formatDateViewAll";
import formatCurrency from "../../../utils/CurrencyFormater";
import { formatAccountNumber } from "../../../components/_utils/FormatAccountNumber";
import { checkUsertoken } from "../../../redux/features/login/login";
import IfscCodeMapper from "../../../components/_utils/IfscLogoMapper";
import ISO_DataFormatter from "../../../utils/ISO_DataFormatter";
import { displayPlatform } from "../../../components/_utils/DisplayEnvironment/displayenv";
import { displayBrowser } from "../../../components/_utils/DisplayEnvironment/displayenv";
import { displayOS } from "../../../components/_utils/DisplayEnvironment/displayenv";
import TransactionHistory from "../../../components/TransactionHistoryModal";
import BeneAccDetailPagePopup from "../../../components/Accounts/BeneAccDetailPagePopup";
import DeclinedPopup from "../../Accounts/BeneficiaryAccount/BeneAcountApproval/DeclinedPopup";

const InternalTransactionDetails = ({
  internal_txn_id,
  closeInternalTxnDetailModal,
  type,
  error_msg,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const [showDetails, setShowDetails] = useState(true);
  const [infoBox, setInfoBox] = useState(false);
  const [showAdditionalDetails, setShowAdditionalDetails] = useState(false);
  const [viewInternalTxnDetails, setViewInternalTxnDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState(0);
  const [openTransHistory, setOpenTransHistory] = useState(false);
  const [loadingBox, setLoadingBox] = useState(false);
  const [internalTxnDetailID, setInternalTxnDetailID] = useState("");
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showApprovalTimeline, setShowApprovalTimeline] = useState(false);
  const [showDistributionTimeline, setShowDistributionTimeline] =
    useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(error_msg ? error_msg : "");
  const [entityId, setEntityId] = useState("");
  const [checkInternalTransaction, setCheckInternalTransaction] =
    useState(false);
  const [beneAccountDetailpop, setBeneAccountDetailpop] = useState(false);
  const services = useSelector((state) => state.refreshToken.services);
  const { internalTxnDataLoading, internalTxnData } = useSelector(
    (state) => state.internalTxnViewData
  );

  const loginid = JSON.parse(localStorage.getItem("response"));

  useEffect(() => {
    async function fetchData() {
      if (internalTxnDataLoading) setLoading(true);
      try {
        dispatch(checkUsertoken());
        let response;
        if (type === "internal_txn_detail_modal") {
          response = await dispatch(internalTxnAccountDetail(internal_txn_id));
        } else {
          response = await dispatch(internalTxnAccountDetail(id));
        }
        if (response?.error?.message == "Request failed with status code 404") {
          navigate("*");
        }
      } catch (error) {
        console.error("Failed to load the account details", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [id, dispatch]);

  useEffect(() => {
    if (internalTxnData) {
      const newData = internalTxnData?.transaction?.map((account) => ({
        ...account,
        loading: false,
        error: false,
        message: "",
        last_updated_user_id_check: false,
      }));
      setViewInternalTxnDetails(newData);
      setEntityId(internalTxnData?.transaction[0]?.entity_id);
      setInternalTxnDetailID(internalTxnData?.transaction[0]?.txn_id);
    }
  }, [internalTxnData]);

  useEffect(() => {
    const fetchTotalPages = async () => {
      try {
        let response = null;
        if (entityId) {
          dispatch(checkUsertoken());
          response = await dispatch(
            internalTxnRequireApprovalPage({
              entityId: entityId,
              page_no: 1,
            })
          );
        }
        const total_pages = response?.payload?.title?.total_pages || 1;
        setPages(total_pages);
      } catch (err) {
        setLoading(false);
      }
    };

    fetchTotalPages();
  }, [dispatch, entityId]);

  useEffect(() => {
    const fetchAllData = async () => {
      if (!pages) return;
      let allTransactions = [];

      setLoadingBox(true);
      for (let page = 1; page <= pages; page++) {
        try {
          let response = null;
          if (
            entityId &&
            internalTxnData?.transaction[0]?.lifecycle_status === "pending"
          ) {
            dispatch(checkUsertoken());
            response = await dispatch(
              internalTxnRequireApprovalPage({
                entityId: entityId,
                page_no: page,
              })
            );
          }

          const transactionsData = response?.payload?.transactions || [];
          allTransactions = [...allTransactions, ...transactionsData];
        } catch (error) {
          console.error(`Error fetching data for page ${page}:`, error);
          break;
        }
      }
      const transactionExists = allTransactions.some(
        (obj) => obj.txn_id === internalTxnDetailID
      );
      if (transactionExists) {
        setCheckInternalTransaction(transactionExists);
      }
      setLoadingBox(false);
    };
    fetchAllData();
  }, [dispatch, entityId, internalTxnDetailID, pages]);

  const bene_account_id =
    internalTxnData?.transaction?.[0]?.beneficiary_account_id;

  const openTransactionHistory = () => {
    setOpenTransHistory(true);
  };

  const closeTransactionHistory = () => {
    setOpenTransHistory(false);
  };

  const handleDeclinedDetails = (id) => {
    setInternalTxnDetailID(id);
    setShowDeclineModal(true);
  };

  const closeDeclineModal = () => {
    setShowDeclineModal(false);
  };

  const closeBeneAccountDetailModal = () => {
    setBeneAccountDetailpop(false);
  };

  const updateAllAccounts = (updatedAccounts) => {
    setViewInternalTxnDetails(updatedAccounts);
  };

  const showToast = () => {
    toast.info("Coming soon", toast_position);
  };

  const [openApprovalTimeline, setOpenApprovalTimeline] = useState(
    Array(viewInternalTxnDetails?.[0]?.logs?.length || 0).fill(false)
  );

  const [openDistributionTimeline, setOpenDistributionTimeline] = useState(
    Array(viewInternalTxnDetails?.[0]?.distribution_logs?.length || 0).fill(
      false
    )
  );

  const handleApprovalTimeline = (index) => {
    setOpenApprovalTimeline((prevOpenDivs) => {
      if (!Array.isArray(prevOpenDivs)) {
        return [];
      }
      const newOpenDivs = [...prevOpenDivs];
      newOpenDivs[index] = !newOpenDivs[index];
      return newOpenDivs;
    });
    setOpenDistributionTimeline(
      Array(viewInternalTxnDetails[0]?.distribution_logs?.length).fill(false)
    );
  };

  const handleDistributionTimeline = (index) => {
    setOpenDistributionTimeline((prevOpenDivs) => {
      if (!Array.isArray(prevOpenDivs)) {
        return [];
      }
      const newOpenDivs = [...prevOpenDivs];
      newOpenDivs[index] = !newOpenDivs[index];
      return newOpenDivs;
    });
  };

  const handleDistributionClick = () => {
    setShowDistributionTimeline(!showDistributionTimeline);
    if (!showDistributionTimeline) {
      setShowDetails(true);
      setShowAdditionalDetails(false);
      setShowApprovalTimeline(false);
    }
    setOpenApprovalTimeline(false);
  };

  const renderApprovalData = (obj) => {
    return Object.keys(obj).map((key, index, array) => {
      const isLastItem = index === array.length - 1;
      if (key !== "action") {
        return (
          <>
            <div
              key={key}
              className={`flex flex-row items-center ${
                !isLastItem ? "mb-5" : ""
              }`}
            >
              <div className="w-2 border-b-2 border-solid border-[#DADADA]"></div>

              <p
                key={key}
                className="w-full text-[#DADADA] text-sm text-left font-sans font-normal ml-2 make-first-letter-small break-words"
              >{`"${key}": "${obj[key]}"`}</p>
            </div>
          </>
        );
      }
      return null;
    });
  };

  const renderDistributionData = (obj) => {
    return Object.keys(obj).map((key, index, array) => {
      const isLastItem = index === array.length - 1;
      if (key !== "action") {
        return (
          <>
            <div
              key={key}
              className={`flex flex-row items-center ${
                !isLastItem ? "mb-5" : ""
              }`}
            >
              <div className="w-2 border-b-2 border-solid border-[#DADADA]"></div>
              <p
                key={key}
                className="w-full text-[#DADADA] text-sm text-left font-sans font-normal ml-2 make-first-letter-small truncate"
              >{`"${key}": "${obj[key]}"`}</p>
            </div>
          </>
        );
      }
      return null;
    });
  };

  const additional_data = internalTxnData?.transaction[0]?.additional_data;

  const validItems = additional_data?.data ? additional_data?.data : [];

  const attachments = validItems.filter(
    (obj) => (obj.name === "Hyperlink" || obj.name === "File") && "value" in obj
  );

  const showAmountInfo = () => {
    setInfoBox(!infoBox);
  };

  const closeModal = () => {
    if (infoBox) {
      setInfoBox(false);
    }
  };

  const toggleDetails = () => {
    setShowDetails(true);
    setShowAdditionalDetails(false);
  };

  const toggleAdditionalDetails = () => {
    setShowAdditionalDetails(true);
    setShowDetails(false);
  };

  const handleApproveInternalTransaction = async (id) => {
    dispatch(checkUsertoken());
    const paragraphStyle = {
      width: "160px",
      height: "19px",
      textAlign: "left",
      font: "normal normal normal 14px/19px Open Sans",
      letterSpacing: "0px",
      color: "FFFFFF",
      Opacity: 1,
    };
    const divStyle = {
      display: "flex",
      gap: "10px",
      justifyContent: "center",
      alignItems: "center",
    };
    const imageStyle = {
      width: "40px",
      height: "40px",
      Opacity: 1,
    };
    toast(
      <div style={divStyle} className="approving-toast-beneTxn">
        <img src={whiteLoader} alt="" style={imageStyle} />
        <p style={paragraphStyle}> Approving transaction </p>
      </div>,
      {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        fontSize: "20px",
        transition: Bounce,
        style: {
          width: window.innerWidth > 600 ? "252px" : window.innerWidth + "px",
          height: "84px",
          borderRadius: "10px",
          backgroundColor: "#AE9041",
          color: "#fff",
        },
      }
    );
    setTimeout(() => {
      toast.dismiss();
    }, 1000);
    let res;
    if (type === "internal_txn_detail_modal") {
      res = await dispatch(internalTxnApproval(internal_txn_id));
    } else {
      res = await dispatch(internalTxnApproval(id));
    }
    if (res?.payload === undefined) {
      setNetworkError(
        "An error occured while taking action on this transaction"
      );
      setTimeout(() => {
        toast.error(
          "An error occured while taking action on this transaction",
          toast_position
        );
      }, 2000);
    } else if (res?.payload?.errors?.user_unauthorized?.[0]) {
      setTimeout(() => {
        navigate("/feature_not_assigned", {
          state: {
            unavailableService: "VBeneficiaryTxn",
          },
        });
      }, 2000);
    } else if (
      res?.payload?.status === false ||
      res.type === "beneTxnWaitingApproval/rejected"
    ) {
      if (
        res?.payload?.errors?.base[0]?.errors &&
        res?.payload?.errors?.base[0]?.errors[0]?.reason
      ) {
        const error_msg = res?.payload?.errors?.base[0]?.errors[0]?.reason;
        if (!Array.isArray(error_msg)) {
          setErrorMessage(error_msg);
          setTimeout(() => {
            toast.error(error_msg, toast_position);
          }, 1000);
        } else {
          setErrorMessage(res.payload.errors.base[0].errors[0].reason[0]);
          if (res.payload.errors.base[0].errors[0].reason.length >= 1) {
            let msgs = res.payload.errors.base[0].errors[0].reason;
            msgs.forEach((error) => {
              if (res?.payload?.status === false) {
                setTimeout(() => {
                  toast.error(error, toast_position);
                }, 1000);
              }
            });
          }
        }
      } else if (
        res?.payload?.errors?.base[0]?.reason &&
        res?.payload?.errors?.base[0]?.reason[0]
      ) {
        setErrorMessage(res?.payload?.errors?.base[0]?.reason[0]);
        setTimeout(() => {
          toast.error(res?.payload?.errors?.base[0]?.reason[0], toast_position);
        }, 1000);
      } else if (res?.payload?.errors?.base[0]) {
        setErrorMessage(res.payload.errors.base[0]);
        setTimeout(() => {
          toast.error(res.payload.errors.base[0], toast_position);
        }, 2000);
      }
    } else {
      const updatedData = internalTxnData?.transaction?.map((data) => {
        return {
          ...data,
          lifecycle_status: res?.payload?.transactions[0]?.lifecycle_status,
          last_updated_status:
            res?.payload?.transactions[0]?.last_updated_status,
          last_updated_user_id:
            res?.payload?.transactions[0]?.last_updated_user_id,
        };
      });
      setViewInternalTxnDetails(updatedData);
      dispatch(checkUsertoken());
      if (type === "internal_txn_detail_modal") {
        await dispatch(internalTxnAccountDetail(internal_txn_id));
      } else {
        await dispatch(internalTxnAccountDetail(id));
      }
      if (res?.payload?.status === true) {
        setTimeout(() => {
          toast.success(" Transaction approved successfully", toast_position);
        }, 1000);
      }
      if (type === "internal_txn_detail_modal") {
        setTimeout(() => {
          closeInternalTxnDetailModal();
          window.location.reload();
        }, 5000);
      }
    }
  };

  const actionRestricted = () => {
    toast(
      <div className="flex flex-row p-2.5">
        <img src={error_with_white_outlined_circle} className="w-6 h-6" />
        <p className="text-sm ml-2 mt-0.5">Action restricted in popup mode.</p>
      </div>,
      {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
          // width: window.innerWidth > 600 ? "252px" : window.innerWidth + "px",
          // height: "84px",
          // borderRadius: "10px",
          backgroundColor: "#e74c3c",
          color: "#fff",
        },
      }
    );
  };

  return (
    <div
      style={{
        height: type === "internal_txn_detail_modal" ? "100%" : "100vh",
        overflowX: "hidden",
        overflowY: showDeclineModal || openTransHistory ? "hidden" : "scroll",
        scrollbarWidth: "none",
      }}
      onClick={closeModal}
    >
      {loading ? (
        <div className="bene-detail-loader-box m-auto justify-center items-center flex">
          <img src={xpentraloader} alt="" />
        </div>
      ) : (
        <div
          className="internalTxnDetailsPage"
          style={{
            marginTop: type === "internal_txn_detail_modal" ? "" : "76px",
          }}
        >
          {type === "internal_txn_detail_modal" && (
            <div
              className={`${
                type === "internal_txn_detail_modal"
                  ? "flex flex-row justify-between items-center p-5 bg-[#1D3A6D]"
                  : "none"
              }`}
            >
              <div className="flex flex-col space-y-1">
                <p className="text-base text-[#F9F9F9] font-sans font-semibold">
                  Internal Transaction Details
                </p>
              </div>
              <div
                className="flex justify-center items-center cursor-pointer w-7 h-7 rounded-[5px] bg-[#314D7E] hover:bg-[#506994]"
                onClick={() => closeInternalTxnDetailModal()}
              >
                <img src={closeIcon} className="w-4 h-4" />
              </div>
            </div>
          )}
          {openTransHistory && (
            <div className="fixed top-0 left-0 w-screen h-screen z-[9998] bg-[#000] bg-opacity-80">
              <TransactionHistory
                owner_account_id={
                  internalTxnData?.transaction[0]?.owner_account_id
                }
                owner_account_bank_acc_no={
                  internalTxnData?.transaction[0]?.owner_account_bank_acc_no
                }
                txnHistorytype={"internalTxnHistory"}
                onClose={closeTransactionHistory}
              />
            </div>
          )}

          {beneAccountDetailpop && (
            <div className="fixed top-0 left-0 w-screen h-screen z-[9998] bg-[#000] bg-opacity-80">
              <BeneAccDetailPagePopup
                closeBeneAccountDetailModal={closeBeneAccountDetailModal}
                bene_account_id={bene_account_id}
                type={"bene_account_detail_modal"}
              />
            </div>
          )}

          {showDeclineModal && (
            <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9998] bg-[#000] bg-opacity-80">
              <DeclinedPopup
                modal_type="internal_txn_detail_modal"
                onClose={closeDeclineModal}
                accountId={internalTxnDetailID}
                status="internal-transaction"
                allAccounts={viewInternalTxnDetails}
                updateAllAccounts={updateAllAccounts}
                closeBeneAccountDetailModal={closeBeneAccountDetailModal}
                closeInternalTxnDetailModal={closeInternalTxnDetailModal}
              />
            </div>
          )}

          <div className="internaltxn-account-primary-details">
            {internalTxnData?.transaction?.map((account) => (
              <div
                key={account.txn_id}
                className={`w-full h-auto ${
                  type === "internal_txn_detail_modal" ? "" : "mt-7"
                }`}
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  maxWidth: "1024px",
                }}
              >
                <div className="flex flex-row border-b-2 border-solid border-[#1d3a6d]">
                  <div className="flex flex-row my-6 w-1/2 justify-between">
                    <div className="flex flex-col space-y-1">
                      <div className="flex flex-row gap-x-1">
                        <p className="text-[#4DDD37] font-sans font-semibold text-base text-left">
                          &#8377; {formatCurrency(account.total_amount)}
                        </p>
                        <div className="relative">
                          <img
                            src={info}
                            className="w-3 h-3 cursor-pointer relative"
                            onClick={showAmountInfo}
                          />
                          {infoBox && (
                            <div
                              className="w-80 h-auto absolute flex flex-col top-4 z-10 rounded-[20px]"
                              style={{
                                boxShadow: "4px 4px 4px 0px #1D1D1D33",
                              }}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <div className="h-14 bg-[#506994] p-5 rounded-t-[20px]">
                                <div className="flex flex-row justify-between">
                                  <span className="text-xs text-[#DADADA] text-right font-sans font-normal leading-[16.34px]">
                                    Transaction amount
                                  </span>
                                  <span className="text-sm text-[#4DDD37] text-right font-sans font-semibold leading-[19.07px]">
                                    &#8377;{" "}
                                    {formatCurrency(account.total_amount)}
                                  </span>
                                </div>
                              </div>
                              <div className="flex-1 bg-[#314D7E] p-5 rounded-b-[20px]">
                                <div className="flex flex-row justify-between">
                                  <span className=" text-xs text-[#DADADA] font-sans font-normal leading-[16.34px]">
                                    Amount debited
                                  </span>
                                  <span className="text-sm text-[#F9F9F9] text-right font-sans font-semibold leading-[19.07px]">
                                    &#8377;{" "}
                                    {formatCurrency(account.total_amount)}
                                  </span>
                                </div>
                                <div className="my-3 flex items-center justify-center ">
                                  <span className="w-3.5 h-0.5 rounded-[2px] bg-[#BB5353]"></span>
                                </div>
                                <div className="flex flex-row justify-between">
                                  <span className="text-xs text-[#DADADA] font-sans font-normal leading-[16.34px]">
                                    Tax amount
                                  </span>
                                  <span className="text-sm text-[#F9F9F9] text-right font-sans font-semibold leading-[19.07px]">
                                    &#8377; {formatCurrency(account.tax_amount)}
                                  </span>
                                </div>
                                <div className="my-3 flex items-center justify-center ">
                                  <span className="w-3.5 h-0.5 rounded-[2px] bg-[#BB5353]"></span>
                                </div>
                                <div className="flex flex-row justify-between">
                                  <span className="text-xs text-[#DADADA] font-sans font-normal leading-[16.34px]">
                                    Payment charge
                                  </span>
                                  <span className="text-sm text-[#F9F9F9] text-right font-sans font-semibold leading-[19.07px]">
                                    &#8377;{" "}
                                    {formatCurrency(account.payment_charge)}
                                  </span>
                                </div>
                                <div className="my-3 flex items-center justify-center">
                                  <span className="w-4 h-2 text-[#FFFFFF] text-2xl">
                                    =
                                  </span>
                                </div>
                                <div className="mt-8 border border-solid border-[#031F4F]"></div>
                                <div className="flex flex-row justify-between mt-4">
                                  <span className="text-xs text-[#DADADA] font-sans font-normal leading-[16.34px]">
                                    Amount credited
                                  </span>
                                  <span className="text-sm text-[#F9F9F9] text-right font-sans font-semibold leading-[19.07px]">
                                    &#8377;{" "}
                                    {formatCurrency(
                                      account.total_amount -
                                        account.tax_amount -
                                        account.payment_charge
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <p className="text-[#DADADA] text-xs text-left font-sans font-normal">
                        Transaction amount
                      </p>
                    </div>
                    <div className="mr-10 space-y-1">
                      <p className="text-[#F9F9F9]	text-base text-right font-sans font-normal">
                        {account.distribution_utr
                          ? account.distribution_utr
                          : "N/A"}
                      </p>
                      <p className="text-[#DADADA] text-xs text-right font-sans font-normal">
                        UTR number
                      </p>
                    </div>
                  </div>
                  <div className="border border-solid border-[#1d3a6d] my-3.5"></div>
                  <div className="flex flex-row justify-between w-1/2">
                    <div className="ml-10 space-y-1 my-6">
                      <p className="text-[#F9F9F9] text-base text-left font-sans font-normal">
                        {formatDateViewAll(account?.created_on, true, true)}
                      </p>
                      <p className="text-[#DADADA] text-xs text-left font-sans font-normal">
                        Date and time
                      </p>
                    </div>
                    <div
                      className={`w-20 h-full flex flex-col justify-center place-items-center space-y-1 bg-[#506994] ${
                        type === "internal_txn_detail_modal"
                          ? "cursor-default opacity-50"
                          : "cursor-pointer hover:bg-gray-200 hover:bg-opacity-20"
                      }`}
                      onClick={() => {
                        if (type === "internal_txn_detail_modal") {
                          actionRestricted();
                        } else {
                          openTransactionHistory();
                        }
                      }}
                    >
                      <img src={transactionHistory} className="w-8 h-8" />
                      <p className="text-[#F9F9F9] text-[10px] text-center font-sans font-normal">
                        Transaction history
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex flex-row">
                  <div className="flex flex-row py-3.5 w-1/2 justify-between">
                    <div
                      className="p-2 cursor-pointer hover:bg-gray-200 hover:bg-opacity-20 rounded-lg -ml-2.5"
                      onClick={() =>
                        navigate(
                          `/accounts/owner/owner_account_details/${account.owner_account_id}`
                        )
                      }
                    >
                      <div className="flex flex-col space-y-1">
                        <div className="flex flex-row items-center">
                          <p className="text-[#F9F9F9] text-base text-left font-sans font-normal">
                            {formatAccountNumber(
                              account.owner_account_bank_acc_no
                            )}
                          </p>
                          <div className="grid place-content-center w-5 h-5 bg-[#1D3A6D] rounded-md ml-5">
                            <img
                              src={arrowRight}
                              alt="document"
                              className="w-2.5 h-2.5 "
                            />
                          </div>
                        </div>
                        <p className="text-[#DADADA] text-xs text-left font-sans font-normal">
                          Source account
                        </p>
                      </div>
                    </div>
                    <div className="bg-[#ffffff] rounded-[20px] flex items-center justify-around h-7 w-20 mt-2 mr-10">
                      <img
                        src={require("../../../assets/IfscLogo/" +
                          IfscCodeMapper(account.owner_account_ifsc_code))}
                        alt="Source account bank name"
                        className="w-14 h-4"
                      />
                    </div>
                  </div>
                  <div className="border border-solid border-[#1d3a6d] mt-3.5 h-9"></div>
                  <div className="flex flex-row py-3.5 w-1/2 justify-between">
                    <div
                      className="p-2 cursor-pointer hover:bg-gray-200 hover:bg-opacity-20 rounded-lg ml-8"
                      onClick={() => showToast()}
                    >
                      <div className="flex flex-col space-y-1">
                        <div className="flex flex-row items-center">
                          <p className="text-[#F9F9F9] text-base text-left font-sans font-normal">
                            {formatAccountNumber(
                              account.beneficiary_account_bank_acc_no
                            )}
                          </p>
                          <div className="grid place-content-center w-5 h-5 bg-[#1D3A6D] rounded-md ml-5">
                            <img
                              src={arrowRight}
                              alt="document"
                              className="w-2.5 h-2.5 "
                            />
                          </div>
                        </div>
                        <p className="text-[#DADADA] text-xs text-left font-sans font-normal">
                          Destination account
                        </p>
                      </div>
                    </div>
                    <div className="bg-[#ffffff] rounded-[20px] flex items-center justify-around h-7 w-20 mt-2">
                      <img
                        src={require("../../../assets/IfscLogo/" +
                          IfscCodeMapper(
                            account.beneficiary_account_ifsc_code
                          ))}
                        alt="Destination account bank name"
                        className="w-14 h-4"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex justify-center items-center">
                  <img
                    src={arrow_right_black}
                    className="w-5 h-5 -mt-9"
                    alt="Internal_Transaction_Arrow"
                  />
                </div>

                <div className="flex flex-row -mt-6">
                  <div className="flex flex-row py-5 w-1/2 justify-between">
                    <div className="flex flex-col space-y-1">
                      <p className="text-[#F9F9F9] text-base text-left font-sans font-normal">
                        {account.owner_account_ifsc_code}
                      </p>
                      <p className="text-[#DADADA] text-xs text-left font-sans font-normal">
                        IFSC code
                      </p>
                    </div>
                    <div className="flex flex-col space-y-1 mr-10">
                      <p className="w-80 text-[#F9F9F9] text-base	text-right	font-sans font-normal capitalize truncate block overflow-ellipsis">
                        {account?.payment_method}
                      </p>
                      <p className="text-[#DADADA] text-xs	font-sans font-normal text-right">
                        Payment method
                      </p>
                    </div>
                  </div>
                  <div className="border border-solid border-[#1d3a6d] h-10 mt-7"></div>
                  <div className="flex flex-row w-1/2 py-5 justify-between">
                    <div className="flex flex-col space-y-1 ml-10">
                      <p className="text-[#F9F9F9] text-base font-sans font-normal	text-left">
                        {account.beneficiary_account_ifsc_code}
                      </p>
                      <p className="text-[#DADADA] text-xs	font-sans font-normal text-left">
                        IFSC code
                      </p>
                    </div>
                    <div className="flex flex-col space-y-1">
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              zIndex: 9999,
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                              [`& .${tooltipClasses.arrow}`]: {
                                color: (theme) => theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        title={account.beneficiary_account_name}
                        arrow
                        placement="bottom"
                        PopperProps={{
                          style: {
                            maxWidth: "none",
                          },
                        }}
                      >
                        <p className="w-80 text-[#F9F9F9] text-base text-right	font-sans font-normal capitalize truncate block overflow-ellipsis">
                          {account.beneficiary_account_name.toLowerCase()}
                        </p>
                      </Tooltip>
                      <p className="text-[#DADADA] text-xs	font-sans font-normal text-right">
                        Account name
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div
            className={`${
              type === "internal_txn_detail_modal" ? "w-full" : "w-screen"
            } bg-[#1D3A6D]`}
          >
            <div className="flex flex-row w-full max-w-5xl m-auto">
              <div
                className={`flex flex-col w-1/2 h-auto mt-5 rounded-t-[15px] rounded-bl-[15px] overflow-hidden relative ${
                  internalTxnData?.transaction[0]
                    ?.distribution_lifecycle_status ===
                    "distribution_complete" ||
                  internalTxnData?.transaction[0]?.lifecycle_status ===
                    "abandoned" ||
                  (internalTxnData?.transaction[0]?.lifecycle_status ===
                    "approved" &&
                    internalTxnData?.transaction[0]
                      ?.beneficiary_account_approval_status !== "pending")
                    ? "bg-[#1D3A6D]"
                    : "bg-[#102C5D]"
                }`}
              >
                <div className="pt-px bg-[#314D7E]">
                  <p className="text-[#DADADA] text-xs text-left font-sans font-normal my-2 ml-5">
                    Transaction Timeline
                  </p>
                </div>
                <div className="border border-solid border-[#102C5D]"></div>
                <div className="flex flex-row flex-grow">
                  <div
                    className="Approval-box w-1/2 cursor-pointer bg-[#314D7E] hover:bg-[#506994]"
                    onClick={() => {
                      setShowApprovalTimeline(!showApprovalTimeline);
                      if (!showApprovalTimeline) {
                        setShowDetails(true);
                        setShowAdditionalDetails(false);
                        setShowDistributionTimeline(false);
                      }
                      setOpenDistributionTimeline(false);
                    }}
                  >
                    <div className="flex flex-row justify-between mt-2 mx-5">
                      <p className="text-[#DADADA] text-xs text-left font-sans font-normal">
                        Stage 1 - Approval
                      </p>
                      <div className="grid place-content-center w-5 h-5 bg-[#1D3A6D] rounded-md">
                        <img
                          src={showApprovalTimeline ? arrow_up : arrow_down}
                          alt="document"
                          className="w-2.5 h-2.5 "
                        />
                      </div>
                    </div>
                    {viewInternalTxnDetails?.length >= 1 &&
                      viewInternalTxnDetails?.map((account) =>
                        account?.lifecycle_status === "rejected" ? (
                          <>
                            <p className="text-[#FF5860] text-sm text-left capitalize font-sans font-semibold mt-2.5 mb-1 ml-5">
                              {account.lifecycle_status}
                            </p>
                          </>
                        ) : account?.lifecycle_status === "approved" ? (
                          <>
                            <p className="text-[#B196FA] text-sm text-left capitalize font-sans font-semibold mt-2.5 mb-1 ml-5">
                              {account.lifecycle_status}
                            </p>
                          </>
                        ) : account?.lifecycle_status === "abandoned" ? (
                          <>
                            <p className="text-[#B4B4B4] text-sm text-left capitalize font-sans font-semibold mt-2.5 mb-1 ml-5">
                              {account.lifecycle_status}
                            </p>
                          </>
                        ) : account.last_updated_status === "Approved" &&
                          account.last_updated_user_id ===
                            loginid?.employees?.id ? (
                          <>
                            <p className="text-[#31E5E5] text-sm text-left font-sans font-semibold mt-2.5 mb-1 ml-5">
                              Pending
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="text-[#31E5E5] text-sm text-left font-sans font-semibold mt-2.5 mb-1 ml-5">
                              {services?.find(
                                (service) => service === "ABeneficiaryTxn"
                              ) === undefined
                                ? "Pending"
                                : "Require approval"}
                            </p>
                          </>
                        )
                      )}

                    <p className="text-[#DADADA] text-[10px] text-left font-sans font-normal ml-5">
                      Status
                    </p>
                  </div>
                  <div className="bg-[#314D7E]">
                    <div className="border border-solid border-[#102C5D] h-16 mt-2"></div>
                  </div>
                  <div
                    className={`Distribution-box w-1/2 rounded-br-[15px] ${
                      viewInternalTxnDetails?.length >= 1 &&
                      viewInternalTxnDetails[0]?.distribution_lifecycle_status
                        ? "bg-[#314D7E] hover:bg-[#506994] cursor-pointer"
                        : "bg-[#314D7E] cursor-default"
                    }`}
                    onClick={
                      viewInternalTxnDetails?.length >= 1 &&
                      viewInternalTxnDetails[0]?.distribution_lifecycle_status
                        ? handleDistributionClick
                        : null
                    }
                  >
                    <div className="flex flex-row justify-between mt-2 mx-5">
                      <p
                        className={`text-[#DADADA] text-xs text-left font-sans font-normal ${
                          viewInternalTxnDetails?.length >= 1 &&
                          viewInternalTxnDetails[0]
                            ?.distribution_lifecycle_status
                            ? "opacity-100"
                            : "opacity-50"
                        }`}
                      >
                        Stage 2 - Distribution
                      </p>
                      <div className="grid place-content-center w-5 h-5 bg-[#1D3A6D] rounded-md">
                        <img
                          src={showDistributionTimeline ? arrow_up : arrow_down}
                          alt="document"
                          className={`w-2.5 h-2.5 ${
                            viewInternalTxnDetails?.length >= 1 &&
                            viewInternalTxnDetails[0]
                              ?.distribution_lifecycle_status
                              ? "opacity-100"
                              : "opacity-50"
                          }`}
                        />
                      </div>
                    </div>
                    {viewInternalTxnDetails?.map((account) =>
                      account?.distribution_lifecycle_status ===
                      "distribution_complete" ? (
                        <>
                          <p className="text-[#4DDD37] text-sm text-left font-sans font-semibold mt-2.5 mb-1 ml-5 opacity-100">
                            Distribution complete
                          </p>
                        </>
                      ) : account?.distribution_lifecycle_status ===
                          "rejected" ||
                        account?.distribution_lifecycle_status === "failed" ? (
                        <>
                          <p className="text-[#FF5860] text-sm text-left font-sans font-semibold mt-2.5 mb-1 ml-5 opacity-100">
                            {`Distribution ${account?.distribution_lifecycle_status}`}
                          </p>
                        </>
                      ) : account?.distribution_lifecycle_status ===
                        "abandoned" ? (
                        <>
                          <p className="text-[#B4B4B4] text-sm text-left font-sans font-semibold mt-2.5 mb-1 ml-5 opacity-100">
                            {"Distribution".concat(
                              " ",
                              account.distribution_lifecycle_status
                            )}
                          </p>
                        </>
                      ) : account?.distribution_lifecycle_status ? (
                        <>
                          <p className="text-[#FBC02D] text-sm text-left font-sans font-semibold mt-2.5 mb-1 ml-5 opacity-100">
                            {account.distribution_lifecycle_status === "created"
                              ? "Distribution created"
                              : account.distribution_lifecycle_status ===
                                "approved"
                              ? "Distribution approved"
                              : account.distribution_lifecycle_status ===
                                "awaiting_funds"
                              ? "Awaiting funds"
                              : account.distribution_lifecycle_status ===
                                "distribution_initiated"
                              ? "Distribution initiated"
                              : account.distribution_lifecycle_status}
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            className={`${
                              account?.distribution_lifecycle_status
                                ? "opacity-100 text-[#FBC02D]"
                                : "opacity-50 text-[#F9F9F9]"
                            } text-sm text-left font-sans font-semibold mt-2.5 mb-1 ml-5`}
                          >
                            {account?.distribution_lifecycle_status
                              ? account?.distribution_lifecycle_status
                              : "N/A"}
                          </p>
                        </>
                      )
                    )}

                    <p
                      className={`text-[#DADADA] text-[10px] text-left font-sans font-normal ml-5 ${
                        internalTxnData?.transaction[0]
                          ?.distribution_lifecycle_status
                          ? "opacity-100"
                          : "opacity-50"
                      }`}
                    >
                      Status
                    </p>
                  </div>
                </div>
                {viewInternalTxnDetails?.length >= 1 &&
                  viewInternalTxnDetails?.map((account) =>
                    account?.lifecycle_status === "rejected" ? (
                      <>
                        <div className="px-5 h-3 absolute w-full top-28 mt-1.5">
                          <div className="bg-[#102C5D] w-full h-full rounded-[15px] relative">
                            <progress
                              className="w-full h-2 progress-rejected-approval absolute top-0.5 left-0 px-0.5 pt-px"
                              value={0.5}
                            />
                          </div>
                        </div>
                      </>
                    ) : account?.distribution_lifecycle_status === "rejected" ||
                      account?.distribution_lifecycle_status === "failed" ? (
                      <>
                        <div className="px-5 h-3 absolute w-full top-28 mt-1.5">
                          <div className="bg-[#102C5D] w-full h-full rounded-[15px] relative">
                            <progress
                              className="w-full h-2 progress-rejected-approval absolute top-0.5 left-0 px-0.5 pt-px"
                              value={1}
                            />
                          </div>
                        </div>
                      </>
                    ) : account?.lifecycle_status === "abandoned" ||
                      account?.distribution_lifecycle_status === "abandoned" ? (
                      <>
                        <div className="px-5 h-3 absolute w-full top-28 mt-1.5">
                          <div className="bg-[#102C5D] w-full h-full rounded-[15px] relative">
                            <progress
                              className="w-full h-2 progress-abandoned-transaction absolute top-0.5 left-0 px-0.5 pt-px"
                              value={`${
                                account?.lifecycle_status === "abandoned"
                                  ? 0.5
                                  : account?.distribution_lifecycle_status ===
                                    "abandoned"
                                  ? 1
                                  : 0.5
                              }`}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="px-5 h-3 absolute w-full top-28 mt-1.5">
                          <div className="bg-[#102C5D] w-full h-full rounded-[15px] relative">
                            <progress
                              className="w-full h-2 progress-require-approval absolute top-0.5 left-0 px-0.5 pt-px"
                              value={`${
                                account?.distribution_lifecycle_status ===
                                "distribution_complete"
                                  ? 1
                                  : account?.distribution_lifecycle_status
                                  ? 0.75
                                  : 0.25
                              }`}
                            />
                          </div>
                        </div>
                      </>
                    )
                  )}
              </div>
              {viewInternalTxnDetails?.map((account) =>
                loadingBox && account.lifecycle_status === "pending" ? (
                  <>
                    <div className="w-1/2 flex flex-row">
                      <div className="flex-grow w-3/5 h-[106px] mt-[52px] bg-[#102C5D]"></div>
                      <div className="flex justify-center items-center w-2/5 h-[106px] mt-[52px] bg-[#102C5D] rounded-tr-[15px] rounded-br-[15px]">
                        <img src={xpentraloader} className="w-10 h-10 loader" />
                      </div>
                    </div>
                  </>
                ) : networkError || errorMessage || account.message ? (
                  <>
                    <div className="w-1/2 flex flex-row">
                      <div className="flex-grow w-1/12 h-[106px] mt-[52px] bg-[#102C5D]"></div>
                      <div className="flex-grow w-11/12 h-[106px] mt-[52px] bg-[#BB5353] rounded-tr-[15px] rounded-br-[15px] p-4 space-y-0.5">
                        <div className="flex flex-row h-full">
                          <div className="flex-none w-[10%] mt-2">
                            <img
                              src={skipping_verification}
                              className="h-7 w-7"
                            />
                          </div>
                          <div className="flex-1 flex-col space-y-1 ml-2.5">
                            <p className="text-[#F9F9F9] text-sm text-left font-sans font-normal">
                              Failed to take action
                            </p>

                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    zIndex: 9999,
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={
                                networkError
                                  ? "An error occurred while taking action on this transaction"
                                  : errorMessage
                                  ? errorMessage
                                  : account.message
                                  ? account.message
                                  : ""
                              }
                              arrow
                              placement="bottom"
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                },
                              }}
                            >
                              <p className="text-[#DADADA] text-xs text-left font-sans font-normal line-clamp">
                                {networkError
                                  ? "An error occurred while taking action on this transaction"
                                  : errorMessage
                                  ? errorMessage
                                  : account.message
                                  ? account.message
                                  : ""}
                              </p>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : account.last_updated_status === "Approved" &&
                  account.lifecycle_status === "pending" &&
                  account.last_updated_user_id === loginid?.employees?.id ? (
                  <>
                    <div className="w-1/2 flex flex-row">
                      <div className="flex-grow w-1/12 h-[106px] mt-[52px] bg-[#102C5D]"></div>
                      <div className="flex-grow w-11/12 h-[106px] mt-[52px] bg-[#314D7E] rounded-tr-[15px] rounded-br-[15px] p-5 space-y-1">
                        <p className="text-[#31E5E5] text-sm text-left font-sans font-normal">
                          Further approval pending
                        </p>
                        <p className="text-[#DADADA] text-xs text-left font-sans font-normal">
                          This transaction has been approved by you, but it
                          requires another user's approval before settlement.
                        </p>
                      </div>
                    </div>
                  </>
                ) : account.last_updated_status === "Approved" &&
                  account.lifecycle_status === "pending" &&
                  services?.find((service) => service === "ABeneficiaryTxn") ===
                    undefined ? (
                  <>
                    <div className="w-1/2 flex flex-row">
                      <div className="flex-grow w-1/12 h-[106px] mt-[52px] bg-[#102C5D]"></div>
                      <div className="flex-grow w-11/12 h-[106px] mt-[52px] bg-[#314D7E] rounded-tr-[15px] rounded-br-[15px] p-5 space-y-1">
                        <p className="text-[#31E5E5] text-sm text-left font-sans font-normal">
                          Further approval pending
                        </p>
                        <p className="text-[#DADADA] text-xs text-left font-sans font-normal">
                          This transaction requires another user's approval
                          before settlement.
                        </p>
                      </div>
                    </div>
                  </>
                ) : account?.lifecycle_status === "rejected" ? (
                  <>
                    <div className="w-1/2 flex flex-row">
                      <div className="flex-grow w-1/12 h-[106px] mt-[52px] bg-[#102C5D]"></div>
                      <div className="flex-grow w-11/12 h-[106px] mt-[52px] bg-[#314D7E] rounded-tr-[15px] rounded-br-[15px] p-5 space-y-0.5">
                        <p className="text-[#FF5860] text-sm text-left font-sans font-normal">
                          Rejected reason
                        </p>

                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                zIndex: 9999,
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                                [`& .${tooltipClasses.arrow}`]: {
                                  color: (theme) => theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          title={account.rejection_reason}
                          arrow
                          placement="bottom"
                          PopperProps={{
                            style: {
                              maxWidth: "none",
                            },
                          }}
                        >
                          <p className="text-[#DADADA] text-xs text-justify font-sans font-normal line-clamp">
                            {account.rejection_reason}
                          </p>
                        </Tooltip>
                      </div>
                    </div>
                  </>
                ) : account?.beneficiary_account_approval_status ===
                  "pending" ? (
                  <>
                    <div className="w-1/2 flex flex-row">
                      <div className="flex-grow w-1/12 h-[106px] mt-[52px] bg-[#102C5D]"></div>
                      <div className="flex-grow w-11/12 h-[106px] mt-[52px] bg-[#314D7E] rounded-tr-[15px] rounded-br-[15px] px-5 py-4">
                        <p className="text-[#31E5E5] text-sm text-left font-sans font-normal">
                          Account approval pending
                        </p>
                        <p className="text-[#DADADA] text-xs text-left font-sans font-normal mt-1">
                          {services?.find(
                            (service) => service === "ABeneficiaryTxn"
                          ) !== undefined
                            ? "Account is not approved, approve account before approving transaction."
                            : "To approve the account, please contact the authorized person."}
                        </p>
                        <div
                          className={`flex justify-between items-center w-[150px] h-7 py-1 px-2.5 mt-2 rounded-[5px] bg-[#1D3A6D] ${
                            services?.find(
                              (service) => service === "ABeneficiaryTxn"
                            ) !== undefined
                              ? "opacity-100 cursor-pointer hover:bg-gray-200 hover:bg-opacity-20"
                              : "opacity-50 cursor-not-allowed"
                          }`}
                          onClick={() => {
                            if (
                              services?.find(
                                (service) => service === "BAAccount"
                              ) !== undefined
                            ) {
                              setBeneAccountDetailpop(true);
                            } else {
                              navigate("/feature_not_assigned", {
                                state: {
                                  unavailableService: "BAAccount",
                                },
                              });
                            }
                          }}
                        >
                          <p className="text-xs text-[#FFFFFF] font-sans font-regular">
                            Approve Account
                          </p>
                          <img
                            src={arrow_right_white_filled}
                            className="w-3 h-3"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : account?.beneficiary_account_approval_status ===
                    "approved" && account.lifecycle_status === "pending" ? (
                  <>
                    <div className="w-1/2 flex flex-row mt-[52px] bg-[#102C5D] rounded-tr-[15px] rounded-br-[15px]">
                      <div className="flex-grow w-3/5 h-[106px]"></div>
                      <div
                        className={`flex flex-row w-2/5 h-[106px] rounded-[15px] overflow-hidden ${
                          checkInternalTransaction
                            ? "opacity-100"
                            : "opacity-50"
                        }`}
                      >
                        <div
                          className={`w-1/2 h-full bg-[#F05A6A]  flex flex-col justify-center place-items-center space-y-1 ${
                            checkInternalTransaction
                              ? "hover:opacity-80 cursor-pointer"
                              : "cursor-not-allowed"
                          }`}
                          onClick={() => {
                            if (
                              services?.find(
                                (service) => service === "ABeneficiaryTxn"
                              ) !== undefined &&
                              checkInternalTransaction
                            ) {
                              handleDeclinedDetails(account.txn_id);
                            } else {
                              navigate("/feature_not_assigned", {
                                state: {
                                  unavailableService: "ABeneficiaryTxn",
                                },
                              });
                            }
                          }}
                        >
                          <img src={declineImage} className="w-7 h-7" />
                          <p className="text-[#FFFFFF] text-xs text-center font-sans font-normal">
                            Reject
                          </p>
                        </div>
                        <div
                          className={`w-1/2 h-full bg-[#69B76F] flex flex-col justify-center place-items-center space-y-1 ${
                            checkInternalTransaction
                              ? "hover:opacity-80 cursor-pointer"
                              : " cursor-not-allowed"
                          }`}
                          onClick={() => {
                            if (
                              services?.find(
                                (service) => service === "ABeneficiaryTxn"
                              ) !== undefined &&
                              checkInternalTransaction
                            ) {
                              handleApproveInternalTransaction(account.txn_id);
                            } else {
                              navigate("/feature_not_assigned", {
                                state: {
                                  unavailableService: "ABeneficiaryTxn",
                                },
                              });
                            }
                          }}
                        >
                          <img src={approveImage} className="w-7 h-7" />
                          <p className="text-[#FFFFFF] text-xs text-center font-sans font-normal">
                            Approve
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="w-1/2 flex flex-row">
                      <div className="flex-grow w-5/12 h-[106px] mt-[52px] bg-[#1D3A6D]"></div>
                      <div className="flex-grow w-7/12 h-[106px] mt-[52px] bg-[#1D3A6D]"></div>
                    </div>
                  </>
                )
              )}
            </div>
          </div>

          {showApprovalTimeline ? (
            <>
              <div className="bg-[#1D3A6D] py-10">
                {viewInternalTxnDetails?.length >= 1 &&
                  [...viewInternalTxnDetails[0]?.logs]
                    ?.reverse()
                    .map((account, index) => {
                      const trackCount =
                        (index + 1) %
                          viewInternalTxnDetails[0]?.logs?.length ===
                        0;
                      const logs_length =
                        viewInternalTxnDetails[0]?.logs?.length;
                      const num = index + 1;
                      return (
                        <>
                          <div
                            key={index}
                            className="flex fex-row items-start w-full max-w-5xl m-auto space-x-10 relative"
                          >
                            <div
                              className={`absolute border-l-2 border-solid border-[#506994] w-[4%] ${
                                num === logs_length
                                  ? "h-10 duration-500 ease-in-out"
                                  : openApprovalTimeline[index]
                                  ? "h-full duration-500 ease-in-out"
                                  : "h-20 duration-500 ease-in-out"
                              }`}
                            >
                              <div className="absolute flex flex-row w-full">
                                <img
                                  src={timeline_dot}
                                  className="w-5 h-5 mt-[31px] ml-[-11px]"
                                />
                                <div className="w-6 border-t-2 mt-10 ml-1 border-solid border-[#506994]"></div>
                              </div>

                              {showApprovalTimeline && num === 1 && (
                                <>
                                  <div className="absolute border-l-2 border-solid border-[#506994] h-6 ml-[-1.75px] -mt-6"></div>
                                  <div className="absolute border-t-2 border-solid border-[#506994] w-36 ml-0 -mt-6"></div>
                                  <div className="absolute border-r-2 border-solid border-[#506994] h-[18px] ml-36 -mt-10"></div>
                                </>
                              )}
                            </div>

                            <div
                              className={`flex flex-col w-full px-5 py-5 rounded-[15px] bg-[#314D7E] hover:bg-gray-200 hover:bg-opacity-20 cursor-pointer overflow-hidden approval-data ${
                                openApprovalTimeline[index] ? "h-auto" : "h-20"
                              }`}
                              onClick={() => handleApprovalTimeline(index)}
                            >
                              <div className="flex flex-row items-center">
                                <div className="flex flex-row items-center w-3/12 border-r-2 border-solid border-[#1d3a6d]">
                                  <div className="flex flex-col space-y-1 ml-2">
                                    <p className="text-[#FCFCFC] text-sm text-left font-sans font-normal capitalize">
                                      {account.action === "Add Status"
                                        ? account.remark.toLowerCase()
                                        : account.action.toLowerCase()}
                                    </p>
                                    <p className="text-[#DADADA] text-[10px] text-left font-sans font-normal">
                                      Action
                                    </p>
                                  </div>
                                </div>
                                <div className="flex flex-row items-center w-9/12">
                                  {account?.browser_name && (
                                    <div className="flex flex-row items-center w-1/3 border-r-2 border-solid border-[#1d3a6d]">
                                      <img
                                        src={require("../../../assets/Timeline/" +
                                          displayBrowser(
                                            account?.browser_name
                                          ) +
                                          ".svg")}
                                        alt="Browser"
                                        className="w-7 h-7 ml-4"
                                      />
                                      <div className="flex flex-col space-y-1 ml-3">
                                        <p className="text-[#FCFCFC] text-sm text-left font-sans font-normal capitalize">
                                          {account?.browser_name ===
                                          "Microsoft Edge (Chromium)"
                                            ? "Microsoft Edge"
                                            : account.browser_name}
                                        </p>
                                        <p className="text-[#DADADA] text-[10px] text-left font-sans font-normal">
                                          Browser
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                  {account?.platform && (
                                    <div className="flex flex-row items-center w-1/3 border-r-2 border-solid border-[#1d3a6d]">
                                      <img
                                        src={require("../../../assets/Timeline/" +
                                          displayPlatform(account?.platform) +
                                          ".svg")}
                                        alt="Platform"
                                        className="w-7 h-7 ml-4"
                                      />
                                      <div className="flex flex-col space-y-1 ml-3">
                                        <p className="text-[#FCFCFC] text-sm text-left font-sans font-normal capitalize">
                                          {account?.platform}
                                        </p>
                                        <p className="text-[#DADADA] text-[10px] text-left font-sans font-normal">
                                          Platform
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                  {account?.os_version && (
                                    <div className="flex flex-row items-center w-1/3">
                                      <img
                                        src={require("../../../assets/Timeline/" +
                                          displayOS(account?.os_version) +
                                          ".svg")}
                                        alt="OS version"
                                        className="w-7 h-7 ml-4"
                                      />
                                      <div className="flex flex-col space-y-1 ml-3">
                                        <p className="text-[#FCFCFC] text-sm text-left font-sans font-normal capitalize">
                                          {account?.os_version}
                                        </p>
                                        <p className="text-[#DADADA] text-[10px] text-left font-sans font-normal">
                                          Operating system
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className="grid place-content-center w-5 h-5 bg-[#1D3A6D] rounded-md">
                                  <img
                                    src={
                                      openApprovalTimeline[index]
                                        ? arrow_up
                                        : arrow_down
                                    }
                                    alt="document"
                                    className="w-2.5 h-2.5 "
                                  />
                                </div>
                              </div>
                              {openApprovalTimeline[index] && (
                                <>
                                  <div className="ml-8 mt-5">
                                    {renderApprovalData(account)}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          {num !== logs_length && (
                            <>
                              <div className="max-w-5xl m-auto h-5 border-l-2 border-solid border-[#506994]"></div>
                            </>
                          )}
                        </>
                      );
                    })}
              </div>
            </>
          ) : showDistributionTimeline ? (
            <>
              <div className="bg-[#1D3A6D] py-10">
                {viewInternalTxnDetails?.length >= 1 &&
                  [...viewInternalTxnDetails[0]?.distribution_logs]
                    ?.reverse()
                    .map((account, index) => {
                      const trackCount =
                        (index + 1) %
                          viewInternalTxnDetails[0]?.distribution_logs
                            ?.length ===
                        0;
                      const distributionLogs_length =
                        viewInternalTxnDetails[0]?.distribution_logs?.length;
                      const num = index + 1;
                      return (
                        <>
                          <div
                            key={index}
                            className="flex fex-row items-start w-full max-w-5xl m-auto space-x-10 relative"
                          >
                            <div
                              className={`absolute border-l-2 border-solid border-[#506994] w-[4%] ${
                                num === distributionLogs_length
                                  ? "h-10 duration-500 ease-in-out"
                                  : openDistributionTimeline[index]
                                  ? "h-full duration-500 ease-in-out"
                                  : "h-20 duration-500 ease-in-out"
                              }`}
                            >
                              <div className="absolute flex flex-row w-full">
                                <img
                                  src={timeline_dot}
                                  className="w-5 h-5 mt-[31px] ml-[-11px]"
                                />
                                <div className="w-6 border-t-2 mt-10 ml-1 border-solid border-[#506994]"></div>
                              </div>

                              {showDistributionTimeline && num === 1 && (
                                <>
                                  <div className="absolute border-l-2 border-solid border-[#506994] h-6 ml-[-1.75px] -mt-6"></div>
                                  <div className="absolute border-t-2 border-solid border-[#506994] w-96 ml-0 -mt-6"></div>
                                  <div className="absolute border-r-2 border-solid border-[#506994] h-[18px] ml-96 -mt-10"></div>
                                </>
                              )}
                            </div>
                            <div
                              className={`flex flex-col w-full px-5 py-5 rounded-[15px] bg-[#314D7E] hover:bg-gray-200 hover:bg-opacity-20 cursor-pointer overflow-hidden distribution-data ${
                                openDistributionTimeline[index]
                                  ? "h-auto"
                                  : "h-20"
                              }`}
                              onClick={() => handleDistributionTimeline(index)}
                            >
                              <div className="flex flex-row justify-between items-center">
                                <div className="flex flex-row items-center">
                                  <div className="flex flex-col space-y-1 ml-2">
                                    <p className="text-[#FCFCFC] text-sm text-left font-sans font-normal capitalize">
                                      {account.action === "Add Status"
                                        ? account.remark.toLowerCase()
                                        : account.action.toLowerCase()}
                                    </p>
                                    <p className="text-[#DADADA] text-[10px] text-left font-sans font-normal">
                                      Action
                                    </p>
                                  </div>
                                </div>
                                <div className="grid place-content-center w-5 h-5 bg-[#1D3A6D] rounded-md">
                                  <img
                                    src={
                                      openDistributionTimeline[index]
                                        ? arrow_up
                                        : arrow_down
                                    }
                                    alt="document"
                                    className="w-2.5 h-2.5 "
                                  />
                                </div>
                              </div>
                              {openDistributionTimeline[index] && (
                                <>
                                  <div className="ml-2 mt-5">
                                    {renderDistributionData(account)}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          {num !== distributionLogs_length && (
                            <>
                              <div className="max-w-5xl m-auto h-5 border-l-2 border-solid border-[#506994]"></div>
                            </>
                          )}
                        </>
                      );
                    })}
              </div>
            </>
          ) : (
            <div
              className={`details-div-trans mb-5 bg-[#1d3a6d] ${
                type === "internal_txn_detail_modal" ? "w-full" : "w-screen"
              }`}
            >
              <div className="detail-section-trans h-auto bg-[#1d3a6d] m-auto max-w-5xl">
                <div className="buttonsfordetails">
                  <button
                    className={`DetailsButton w-20 h-7 mr-5 ${
                      showDetails ? "effective" : "hover:bg-[#A3A3A3]"
                    }`}
                    onClick={toggleDetails}
                  >
                    <span
                      className={`text-xs text-center font-sans font-semibold ${
                        showDetails ? "text-black" : "text-white"
                      } `}
                    >
                      Details
                    </span>
                  </button>
                  <button
                    className={`AdditionalDetailsButton w-36 h-7 ${
                      showAdditionalDetails ? "effective" : "hover:bg-[#A3A3A3]"
                    }`}
                    onClick={toggleAdditionalDetails}
                  >
                    <span
                      className={`text-xs text-center font-sans font-semibold ${
                        showAdditionalDetails ? "text-black" : "text-white"
                      }`}
                    >
                      Additional Details
                    </span>
                  </button>
                </div>
                {showDetails &&
                  viewInternalTxnDetails?.map((account) => (
                    <div
                      className={`flex my-8 ${
                        account?.distribution_lifecycle_status ===
                        "distribution_complete"
                          ? "justify-between"
                          : "space-x-5"
                      }`}
                    >
                      <div
                        className="
                           w-[328px]
                        h-auto px-7 py-5 rounded-[20px] bg-[#314D7E] self-start"
                      >
                        <p className="text-[#DADADA] text-sm text-left font-sans font-semibold pb-5">
                          Origin Details
                        </p>
                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                zIndex: 9999,
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                                [`& .${tooltipClasses.arrow}`]: {
                                  color: (theme) => theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          title={
                            account?.logs?.[0]?.first_name +
                            " " +
                            account?.logs?.[0]?.last_name[0].toUpperCase() +
                            account?.logs?.[0]?.last_name.slice(1)
                          }
                          arrow
                          placement="bottom"
                          PopperProps={{
                            style: {
                              maxWidth: "none",
                            },
                          }}
                        >
                          <p className="text-[#F9F9F9] text-sm text-left font-sans font-normal mb-1 truncate">
                            {account?.logs?.[0]?.first_name +
                              " " +
                              account?.logs?.[0]?.last_name[0].toUpperCase() +
                              account?.logs?.[0]?.last_name.slice(1)}
                          </p>
                        </Tooltip>
                        <p className="text-[#DADADA] text-xs text-left font-sans font-normal">
                          Initiated by
                        </p>
                      </div>

                      {account?.distribution_lifecycle_status ===
                      "distribution_complete" ? (
                        <>
                          <div
                            className="
                           w-[328px]
                        h-auto px-7 py-5 rounded-[20px] bg-[#314D7E]"
                          >
                            <p className="text-[#DADADA] text-sm text-left font-sans font-semibold pb-5">
                              Settlement Details
                            </p>
                            <p className="text-[#F9F9F9] text-sm text-left font-sans font-normal mb-1">
                              {account?.distribution_credited_at
                                ? ISO_DataFormatter(
                                    account?.distribution_credited_at
                                  )
                                : "N/A"}
                            </p>
                            <p className="text-[#DADADA] text-xs text-left font-sans font-normal border-b border-solid border-[#102C5D] pb-2.5">
                              Settled on
                            </p>
                            <p className="text-[#F9F9F9] text-sm text-left font-sans font-normal pt-2.5 mb-1 block w-full truncate">
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                      [`& .${tooltipClasses.arrow}`]: {
                                        color: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title={account.distribution_settlement_id}
                                arrow
                                placement="bottom"
                                PopperProps={{
                                  style: {
                                    maxWidth: "none",
                                  },
                                }}
                              >
                                <p className="text-[#F9F9F9] text-sm font-sans font-normal text-left truncate block w-full mb-1">
                                  {account?.distribution_settlement_id
                                    ? account?.distribution_settlement_id
                                    : "N/A"}
                                </p>
                              </Tooltip>
                            </p>
                            <p className="text-[#DADADA] text-xs text-left font-sans font-normal">
                              Settlement ID
                            </p>
                          </div>
                          <div className="w-[328px] h-auto px-7 py-5 rounded-[20px] bg-[#314D7E]">
                            <p className="text-sm text-[#DADADA] text-left font-sans font-semibold pb-5">
                              Support Information
                            </p>

                            <p
                              id="id-firstLetter"
                              className="text-[#F9F9F9] text-sm font-sans font-normal text-left block w-full mb-1"
                            >
                              {account.txn_id}
                            </p>

                            <p className="text-[#DADADA] text-xs font-sans font-normal text-left">
                              Transaction ID
                            </p>
                          </div>
                        </>
                      ) : (
                        <div className="w-[328px] h-auto px-7 py-5 rounded-[20px] bg-[#314D7E]">
                          <p className="text-sm text-[#DADADA] text-left font-sans font-semibold pb-5">
                            Support Information
                          </p>

                          <p
                            id="id-firstLetter"
                            className="text-[#F9F9F9] text-sm font-sans font-normal text-left block w-full mb-1"
                          >
                            {account.txn_id}
                          </p>
                          <p className="text-[#DADADA] text-xs font-sans font-normal text-left">
                            Transaction ID
                          </p>
                        </div>
                      )}
                    </div>
                  ))}
                {showAdditionalDetails && (
                  <>
                    <div className="flex flex-row items-start gap-x-5">
                      <div
                        className={`my-8 p-5 bg-[#314D7E] h-auto rounded-[20px] ${
                          validItems?.length === 0
                            ? "w-2/5"
                            : validItems?.length === 1
                            ? "w-2/6"
                            : validItems?.length === 2
                            ? "w-4/6"
                            : attachments.length > 0
                            ? "w-[676px]"
                            : "w-full"
                        }`}
                      >
                        <p className="text-[#DADADA] text-sm text-left font-sans font-semibold mb-5">
                          Additional Details
                        </p>

                        {validItems?.length === 0 ? (
                          <>
                            <p className="text-left text-[#DADADA] text-xs font-sans font-normal">
                              Additional details are not supported for the
                              selected category.
                            </p>
                          </>
                        ) : attachments.length > 0 ? (
                          <div className="mx-auto">
                            {validItems?.map((obj, index) => {
                              if (index % 2 === 0) {
                                const isLastRow =
                                  index + 2 >= validItems?.length;
                                const isFirstRow = index === 0;

                                return (
                                  <div
                                    key={index}
                                    className={`flex flex-row ${
                                      !isFirstRow ? "mt-2.5" : ""
                                    }`}
                                  >
                                    {validItems
                                      .slice(index, index + 2)
                                      .map((subItem, subIndex) => {
                                        const isLastInRow = subIndex === 1;
                                        const nextItemExists =
                                          validItems[index + subIndex + 0] !==
                                          undefined;

                                        if (
                                          subItem?.name !== "Hyperlink" &&
                                          subItem?.name !== "File"
                                        ) {
                                          return (
                                            <>
                                              <div
                                                key={subIndex}
                                                className={`flex flex-col w-72 ${
                                                  !isLastRow
                                                    ? "border-b border-solid border-[#102C5D]"
                                                    : ""
                                                }`}
                                              >
                                                {subItem?.value ? (
                                                  <Tooltip
                                                    componentsProps={{
                                                      popper: {
                                                        sx: {
                                                          zIndex: 9999,
                                                          [`& .${tooltipClasses.tooltip}`]:
                                                            {
                                                              backgroundColor: (
                                                                theme
                                                              ) =>
                                                                theme.palette
                                                                  .common.black,
                                                            },
                                                          [`& .${tooltipClasses.arrow}`]:
                                                            {
                                                              color: (theme) =>
                                                                theme.palette
                                                                  .common.black,
                                                            },
                                                        },
                                                      },
                                                    }}
                                                    title={
                                                      subItem?.data_type ===
                                                        "toggle" &&
                                                      typeof subItem?.value ===
                                                        "boolean" &&
                                                      subItem?.value == true
                                                        ? "Yes"
                                                        : subItem?.data_type ===
                                                            "multi_select_list" &&
                                                          subItem?.value
                                                            .length !== 0
                                                        ? subItem?.value?.join(
                                                            ","
                                                          )
                                                        : subItem?.data_type ===
                                                          "date_range"
                                                        ? (subItem?.value?.start_date).concat(
                                                            " - ",
                                                            subItem?.value
                                                              ?.end_date
                                                          )
                                                        : subItem?.data_type ===
                                                          "from_year_to_year"
                                                        ? (subItem?.value?.from)
                                                            .toString()
                                                            .concat(
                                                              " - ",
                                                              (subItem?.value?.to).toString()
                                                            )
                                                        : typeof subItem?.value ===
                                                          "object"
                                                        ? "Not Given"
                                                        : subItem.value
                                                    }
                                                    placement="bottom-start"
                                                    PopperProps={{
                                                      modifiers: [
                                                        {
                                                          name: "offset",
                                                          options: {
                                                            offset: [-5, -10],
                                                          },
                                                        },
                                                      ],
                                                    }}
                                                  >
                                                    <p className="text-[#F9F9F9] text-sm text-left font-sans font-normal truncate">
                                                      {subItem?.data_type ===
                                                        "toggle" &&
                                                      typeof subItem?.value ===
                                                        "boolean" &&
                                                      subItem?.value == true
                                                        ? "Yes"
                                                        : subItem?.data_type ===
                                                            "multi_select_list" &&
                                                          subItem?.value
                                                            .length !== 0
                                                        ? subItem?.value?.join(
                                                            ","
                                                          )
                                                        : subItem?.data_type ===
                                                          "date_range"
                                                        ? (subItem?.value?.start_date).concat(
                                                            " - ",
                                                            subItem?.value
                                                              ?.end_date
                                                          )
                                                        : subItem?.data_type ===
                                                          "from_year_to_year"
                                                        ? (subItem?.value?.from)
                                                            .toString()
                                                            .concat(
                                                              " - ",
                                                              (subItem?.value?.to).toString()
                                                            )
                                                        : typeof subItem?.value !==
                                                            "object" &&
                                                          (subItem?.name !==
                                                            "Hyperlink" ||
                                                            subItem?.name !==
                                                              "File")
                                                        ? subItem.value
                                                        : "Not Given"}
                                                    </p>
                                                  </Tooltip>
                                                ) : (
                                                  <p className="text-[#F9F9F9] text-sm text-left font-sans font-normal capitalize">
                                                    {subItem?.data_type ===
                                                      "toggle" &&
                                                    subItem.hasOwnProperty(
                                                      "value"
                                                    ) === false
                                                      ? "No"
                                                      : "Not given"}
                                                  </p>
                                                )}
                                                <p
                                                  className={`text-[#C9C9C9] text-xs text-left font-sans font-normal mt-1 ${
                                                    !isLastInRow ? "mb-2.5" : ""
                                                  }`}
                                                >
                                                  {subItem?.data_type ===
                                                  "from_year_to_year"
                                                    ? "From year".concat(
                                                        " - ",
                                                        "To year"
                                                      )
                                                    : subItem?.data_type ===
                                                      "date_range"
                                                    ? "From date".concat(
                                                        " - ",
                                                        "To date"
                                                      )
                                                    : subItem?.name !==
                                                        "Hyperlink" ||
                                                      subItem?.name !== "File"
                                                    ? subItem?.name
                                                    : "N/A"}
                                                </p>
                                              </div>
                                              {!isLastInRow &&
                                                nextItemExists && (
                                                  <div className="border-r border-solid border-[#102C5D] mx-7"></div>
                                                )}
                                            </>
                                          );
                                        }
                                      })}
                                  </div>
                                );
                              }
                              return null;
                            })}
                          </div>
                        ) : (
                          <div className="mx-auto">
                            {validItems?.map((obj, index) => {
                              if (index % 3 === 0) {
                                const isLastRow =
                                  index + 3 >= validItems?.length;
                                const isFirstRow = index === 0;

                                return (
                                  <div
                                    key={index}
                                    className={`flex flex-row ${
                                      !isFirstRow ? "mt-2.5" : ""
                                    }`}
                                  >
                                    {validItems
                                      .slice(index, index + 3)
                                      .map((subItem, subIndex) => {
                                        const isLastInRow = subIndex === 2;
                                        const nextItemExists =
                                          validItems[index + subIndex + 1] !==
                                          undefined;
                                        return (
                                          <>
                                            <div
                                              key={subIndex}
                                              className={`flex flex-col w-72 ${
                                                !isLastRow
                                                  ? "border-b border-solid border-[#102C5D]"
                                                  : ""
                                              }`}
                                            >
                                              {subItem?.value ? (
                                                <Tooltip
                                                  componentsProps={{
                                                    popper: {
                                                      sx: {
                                                        zIndex: 9999,
                                                        [`& .${tooltipClasses.tooltip}`]:
                                                          {
                                                            backgroundColor: (
                                                              theme
                                                            ) =>
                                                              theme.palette
                                                                .common.black,
                                                          },
                                                        [`& .${tooltipClasses.arrow}`]:
                                                          {
                                                            color: (theme) =>
                                                              theme.palette
                                                                .common.black,
                                                          },
                                                      },
                                                    },
                                                  }}
                                                  title={
                                                    subItem?.data_type ===
                                                      "toggle" &&
                                                    typeof subItem?.value ===
                                                      "boolean" &&
                                                    subItem?.value == true
                                                      ? "Yes"
                                                      : subItem?.data_type ===
                                                          "multi_select_list" &&
                                                        subItem?.value
                                                          .length !== 0
                                                      ? subItem?.value?.join(
                                                          ","
                                                        )
                                                      : subItem?.data_type ===
                                                        "date_range"
                                                      ? (subItem?.value?.start_date).concat(
                                                          " - ",
                                                          subItem?.value
                                                            ?.end_date
                                                        )
                                                      : subItem?.data_type ===
                                                        "from_year_to_year"
                                                      ? (subItem?.value?.from)
                                                          .toString()
                                                          .concat(
                                                            " - ",
                                                            (subItem?.value?.to).toString()
                                                          )
                                                      : typeof subItem?.value ===
                                                        "object"
                                                      ? "Not Given"
                                                      : subItem.value
                                                  }
                                                  placement="bottom-start"
                                                  PopperProps={{
                                                    modifiers: [
                                                      {
                                                        name: "offset",
                                                        options: {
                                                          offset: [-5, -10],
                                                        },
                                                      },
                                                    ],
                                                  }}
                                                >
                                                  <p className="text-[#F9F9F9] text-sm text-left font-sans font-normal truncate">
                                                    {subItem?.data_type ===
                                                      "toggle" &&
                                                    typeof subItem?.value ===
                                                      "boolean" &&
                                                    subItem?.value == true
                                                      ? "Yes"
                                                      : subItem?.data_type ===
                                                          "multi_select_list" &&
                                                        subItem?.value
                                                          .length !== 0
                                                      ? subItem?.value?.join(
                                                          ","
                                                        )
                                                      : subItem?.data_type ===
                                                        "date_range"
                                                      ? (subItem?.value?.start_date).concat(
                                                          " - ",
                                                          subItem?.value
                                                            ?.end_date
                                                        )
                                                      : subItem?.data_type ===
                                                        "from_year_to_year"
                                                      ? (subItem?.value?.from)
                                                          .toString()
                                                          .concat(
                                                            " - ",
                                                            (subItem?.value?.to).toString()
                                                          )
                                                      : typeof subItem?.value !==
                                                        "object"
                                                      ? subItem.value
                                                      : "Not Given"}
                                                  </p>
                                                </Tooltip>
                                              ) : (
                                                <p className="text-[#F9F9F9] text-sm text-left font-sans font-normal capitalize">
                                                  {subItem?.data_type ===
                                                    "toggle" &&
                                                  subItem.hasOwnProperty(
                                                    "value"
                                                  ) === false
                                                    ? "No"
                                                    : "Not given"}
                                                </p>
                                              )}
                                              <p
                                                className={`text-[#C9C9C9] text-xs text-left font-sans font-normal mt-1 ${
                                                  !isLastInRow ? "mb-2.5" : ""
                                                }`}
                                              >
                                                {subItem?.data_type ===
                                                "from_year_to_year"
                                                  ? "From year".concat(
                                                      " - ",
                                                      "To year"
                                                    )
                                                  : subItem?.data_type ===
                                                    "date_range"
                                                  ? "From date".concat(
                                                      " - ",
                                                      "To date"
                                                    )
                                                  : subItem.name}
                                              </p>
                                            </div>
                                            {!isLastInRow && nextItemExists && (
                                              <div className="border-r border-solid border-[#102C5D] mx-7"></div>
                                            )}
                                          </>
                                        );
                                      })}
                                  </div>
                                );
                              }
                              return null;
                            })}
                          </div>
                        )}
                      </div>
                      {attachments.length > 0 && (
                        <div className="my-8 p-5 h-auto w-[328px] rounded-[20px] bg-[#314D7E]">
                          <p className="text-sm text-[#DADADA] font-sans font-semibold mb-2.5">
                            Attachments
                          </p>
                          {attachments.map((obj) => {
                            if (obj?.name === "Hyperlink") {
                              return (
                                <>
                                  <div className="flex flex-col gap-y-0.5">
                                    <p className="text-xs text-[#DADADA] font-sans font-normal pb-0.5">
                                      URL
                                    </p>
                                    <div className="flex flex-col gap-y-0.5 w-72 h-28 p-2.5 rounded-[10px] bg-[#1D3A6D]">
                                      <div className="flex flex-row items-center w-[268px] h-[54px] p-2.5 rounded-[5px] bg-[#314D7E]">
                                        <img
                                          src={default_file}
                                          className="w-9 h-8 items-center"
                                        />
                                        <p className="ml-2.5 text-[10px] text-[#DADADA] font-sans font-normal">
                                          N/A
                                        </p>
                                      </div>
                                      <p className="text-[10px] text-[#31E5E5] font-sans font-normal truncate">
                                        {obj?.value ? (
                                          <a href={obj?.value} target="blank">
                                            {obj?.value}
                                          </a>
                                        ) : (
                                          "Not Given"
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </>
                              );
                            } else {
                              return (
                                <>
                                  <div className="flex flex-col gap-y-0.5 pt-5">
                                    <p className="text-xs text-[#DADADA] font-sans font-normal pb-0.5">
                                      {obj?.value?.metadata?.mime_type ===
                                      "image/png"
                                        ? "Images"
                                        : "Files"}
                                    </p>
                                    <div className="flex flex-row items-center gap-x-2.5 w-72 h-14 p-2.5 rounded-[10px] bg-[#1D3A6D]">
                                      <img
                                        src={
                                          // obj?.value?.metadata?.mime_type ===
                                          // "image/png"
                                          default_file
                                          // : require("../../../assets/FileFormatIcons/" +
                                          //     obj?.value?.metadata?.filename
                                          //       .slice(
                                          //         obj?.value?.metadata?.filename.indexOf(
                                          //           "."
                                          //         ) + 1
                                          //       )
                                          //       .toUpperCase() +
                                          //     ".svg")
                                        }
                                        className="w-9 h-8"
                                      />
                                      <div className="flex flex-col gap-y-0.5">
                                        <p className="text-xs text-[#F9F9F9] font-sans font-normal capitalize">
                                          {obj?.value?.metadata?.filename}
                                        </p>
                                        <p className="text-[10px] text-[#DADADA] font-sans font-normal capitalize">
                                          {obj?.value?.metadata?.size +
                                            " ." +
                                            obj?.value?.metadata?.filename.slice(
                                              obj?.value?.metadata?.filename.indexOf(
                                                "."
                                              ) + 1
                                            )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            }
                          })}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default InternalTransactionDetails;
